export const OrganizerStatusChangedWarning = "Changes have been made to your document. Reloading the page.";
export const ErrorMessages = {
    PreparerDocumentSettingError: "Error occured while loading DocumentSettings",
    HeaderInfoError: "Error occurred while loading Header Information",
    TaxingAuthorityError: "Error occured while loading Taxing Authority",
    SignedDocumentError: "Error occurred while laoding signed documents model",
    AddSignedDocument: "Error occcured while uploading Signed document",
    DeleteSignedDocument: "Error occured while deleting Signed document",
    CompleteManualSign: "Error occured while completing the singed document",
    SummaryDetails: "Error occured while loading the Summary of the document",
    EsignModel: "Error occured while loading Esign Page",
    Signers: "Error occured while loading Signer information",
    ClientAuthentication: "Error occurred while authenticating user",
    ClientValidation: "Error occurred while validating client answer",
    UpadateSpouseMail: "Error occurred while updating spouse email",
    UpdatingMobileNumber: "Error occurred while updating mobile number",
    UpdatingDob: "Error occured while updating Date of Birth",
    SingatureControl: "Error occurred while requesting signature controls",
    DocumentSignatureSetting: "Error occurred while updating document signature settings",
    CompanyDetails: "Error occured while loading Company Details",
    DocumentReviewModel: "Error occured while loading Review Documents",
    RequestDocumentError: "Error occurred while requesting Tax Document model",
    RequestClientType: "Error occurred while fetching the Client Type",
    RequestKBAStatus: "Error occurred while loading Knowledge Based Authentication",
    RequestNextSigner: "Error occured while loading Next Signer Details",
    UpdateProcessInfoError: "Error occurred while updating process information",
    GetLastVisitedStepError: "Error occurred while requesting GetLastVisitedStep",
    GetCurrentStepAndRoleError: "Error occurred while requesting GetCurrentStepAndRole",
    ChangeMobileNumberError: "Unable to update the Mobile Number",
    UploadDocumentError: "Error occurred while processing uploaded document",
    DocumentSubmissionFailed: "Error occurred while processing document submission",
};

export const ValidationContants = {
    PhoneNumberWarning: "Please enter the phone number.",
    PhoneNumberLengthWarning: "Please enter a 10 digit phone number.",
    CountryCodeWarning: "Please select country code.",
    EmailAddressWarning: "Please enter valid email Address."
};

export const OrganizerUploadedDocuments = {
    ModalTitle: "Upload Required Documents",
    DownloadDocumentTitle: "Please download documents to continue",
    DownloadDocumentButtonText: "Download documents requiring signature",
    UploadDocumentTitle: "After manually signing documents please select a method to send them to your tax preparer",
    UploadDocumentSecondaryTitle: "Upload Signed Documents",
    SendByMailText: "Send by Mail",
    SendByFaxText: "Send by Fax",
    FileDeleteConfirmation: "Are you sure you want to delete the file?",
    DeleteSuccess: "File deleted successfully",
    DownloadEfileLink: "/api/Download/GetManualSignDocument/",
    UploadAreaButtonText: "Click to Upload",
    UploadAreaText: "Drag documents here",
    UploadAreaMinimizedText: "Drag documents here or click here to upload",
    UploadAreaTextSeperation: "Or",
    UploadNotAllowedMessage: "Upload not allowed in preview mode",
    SupportedFilesMessage: "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
    ConfirmMessage:
        "All document(s) have been successfully uploaded and will be forwarded to <COMPANYNAME>. Do you want to procced?",
    UploadInProgressMessage:
        "The file upload is in progress. The window can be closed after all documents have finished uploading.",
    UploadInProgressOnNotifyMessage:
        "The file upload is in progress. You can notify after all documents have finished uploading.",
    UploadInProgressOnBackMessage:
        "The file upload is in progress. Please wait until all documents have finished uploading.",
    UploadInProgressMessageTitle: "Confirmation",
    CompleteDocumentUploadBtnText: "Save & Notify CPA",
    NotifyToCPAPendingCloseButtonDisabledTooltip: "Please click 'Save & Notify CPA' to finish the upload.",
    ModalBodyText:
        "Use your phone camera to take pictures of your documents and then upload them. Once you click on Complete, you will not be able to upload further documents.",
    UploadError: "Please upload source documents to notify Tax Professional.",
    UploadDocumentWarningText: "Encrypted files, password-protected, or locked documents are not supported."
};

export const OrganizerConstants = {
    FinishConfirmationTitle: "Organizer Complete",
    FinishConfirmationMessage: `Are you sure you've completed answering all of the information pertaining to you and this tax year ?`,

    FinishConfirmationConfirmText: "Organizer Complete",
    FinishConfirmationCancellText: "Review Missing Information",

    ErrorPage: "/error/",
    SignCompletePageURL: "/signingcomplete/",
    OrganizerSummaryPageURL: "/organizer/",
    OrganizerSignPageURL: "/Organizer/sign/",
    EngagementLetterSignPageURL: "/engagementletter/sign/",
    ViewandDownloadEngagementLetterPageURL: "/engagementletter/signingcomplete/",
    UploadDocumentPageURL: "/documents/",
    ViewDocumentsPageURL: "/viewdocuments/",
    CoverPageURL: "/coverPage/",
    WelcomeScreenURL: "/welcome/",
    ErrorPageURL: "/invalidlink",
    PreparerMessageURL: "/preparermessage/",

    SignDataSavedMessage: "Successfully saved Organizer Data",
    SignDataSavedMessageTitle: "Success",
    RedirectToSummaryPageText: "Go to Summary Page",
    SummaryPageSecondaryMessage: "Please follow these easy steps",
    SummaryPageSignEngagementLetterText: "Sign Engagement Letter",
    SummaryPageCompleteOrganizerText: "Complete Organizer",
    SummaryPageDownloadEngagementLetterText: "Download Engagement Letter",
    SummaryPageDownloadOrganizerText: "Download Organizer",
    SummaryPageUploadDocumentsText: "Upload Documents",
    SummaryPageContinueButtonText: "Click Here to Continue",
    SummaryPageDeclinedEngagementLetterText: "Engagement Letter Declined to Sign",
    UploadDocumentsHeaderText: "Upload Documents",

    RedirectConfirmationMessage: "Your unsaved edits will be discarded, Are you sure ?",
    DownloadOrganizerFileName: "Organizer.pdf",

    PreviewNotAvailable: "Preview not available",
    SignedDocuments: "_Signed Documents.zip"

};

export const EngagementLetterConstants = {
    SignHeaderTitle: "Sign Documents",
    ViewHeaderTitle: "View Signed Documents",
    FinishConfirmationTitle: "Engagement Letter Complete",
    FinishConfirmationMessage: `Are you sure ?`,

    FinishConfirmationConfirmText: "Yes",
    FinishConfirmationCancellText: "cancel",

    SigningCompletedMessage: "Successfully signed Engagement letter, You will be redirected to Organizer page",

    UploadingSignatureMessage: "Uploading Signature ...",
    SubmitDocumentMessage: "Saving Sign info ...",

    SpouseInfoUpdateModalTitle: "Spouse's Signature Required",
    SpouseInfoUpdateModalBodyText:
        "Please provide an email address for your spouse. It may be the same as your own if you share an email account. The recipient will receive a similar request asking them to Sign their forms electronically.",

    SpouseNameTitle: "Spouse's Name : ",
    SpouseEmailTitle: "Email Address : ",

    SpouseEmailValidationError: "Enter a valid email address.",

    DeclineModalBodyText: "If you select CONTINUE, this document will be voided and inaccessible to other signers.",

    DeclineRemarksTextboxLabel: "Please enter the reason for declining below:",
    DeclineRemarksModalTitle: "Decline Engagement Letter",
    DownloadEngagementLetterFileName: "Engagement Letter.pdf",
    DownloadOrganizerFileName: "Organizer Signature Document.pdf",
    AddEfileDocSigningNotSupported: "Additional Document Signing Not Supported. Please login from your desktop/tablet to complete signing.",
    DownloadErrorMessages: "Error while downloading the file.",
};

export const SourceDocumentConstants = {
    Uploaded: "Uploaded",
    Added: "Added",
    Deleted: "Deleted",
    SourceDocumentEnabled: "sourceDocumentEnabled"
};

export const LocalStorageConstant = {
    Token: "token",
    RefreshToken: "refreshToken",
    EncryptedClientId: "encryptedClientId",
    UploadDocumentSetting: "uploadDocumentSetting"
};

export const OTPPageConstants = {
    SuccessMessage: {
        OTPGenerateSuccess: "A one-time access code was sent to your email.",
        mobileOTPGenerateSuccess: "A one-time access code was sent to you via a text message."
    },
    ErrorMessage: {
        lockedByOTP: "Maximum retry reached for access code",
        serverError: "Internal Server Error, Please try to refresh",
        OTPGenerateFailed: "Unable to generate access code, please try again",
        OTPExpired: "Entered access code has expired",
        OTPError: "Please enter a valid access code",
        Unauthorized: "Session expired"
    }
};

export const DisplayError = [
    "ERROR_DELETED_RETURN",
    "ERROR_RECALLED_RETURN",
    "ERROR_INVALID_LINK",
    "OTP_LOCKED_MESSAGE",
    "SSN_LOCKED_MESSAGE",
    "SSN_LOCKED",
    "OTP_LOCKED",
    "RETURN_DECLINED_FOR_SIGN"
];

export const fileTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];

export const ClientInfoActions = {
    SuccessMessage: {
        accountDetailsUpdateSuccess: "Account Details Updated successfully"
    },
    ErrorMessage: {
        accountDetailsUpdateError: "Failed To Account Details",
        taxpayerFetchError: "Failed To Fetch User Info"
    }
};
export const AdditionalEsignAdjustments = {
    CheckBoxTopAdjustment: 15,
    PdfViewerTopAdjustment: 10
}

export const SignedDocumentDownload = {
    SuccessMessage: {
        fileReady: "Document Preparation in progress, please Retry!",
    },
    ErrorMessage: {
        failedToDownload: "Failed To downLoad"
    }
};


export const delayConstant = {
    signalrDownloadDelay: 20000, // 20 seconds delay for signalr download average after ziping files
};

export enum StatusCode {
    Conflict = 409,
    NotFound = 404,
    Gone = 410,
    Ok = 200,
    Locked = 423,
    OrganizerUnavailable = 601,
}
